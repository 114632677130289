import { useState, useEffect } from "react"
import Multiselect from 'multiselect-react-dropdown';
import { Amplify, Auth } from 'aws-amplify';
import { user_config, update_user, animated_background } from '../components/configs';
import axios from "axios";
import DatePicker from "react-datepicker";
import PhoneInput from 'react-phone-number-input'
import "react-datepicker/dist/react-datepicker.css";
import '../styles/salvio.css'
import 'react-phone-number-input/style.css'
import questionnaire from '../Data/questions.json'
import * as moment from 'moment'
import CheckBoxSet from "../components/CheckBoxSet"

export default function Settings(props) {
  const [waiting, setwaiting] = useState(true)
  const [user, setUser] = useState()
  const [user_id, setUserId] = useState(props.userId)
  const [user_name, set_name] = useState()
  const [user_surname, set_surname] = useState()
  const [user_username, set_username] = useState()
  const [user_email, set_email] = useState()
  const [user_birthday, set_birthday] = useState(new Date())
  const [user_gender, set_gender] = useState()
  const [user_phone, set_phone] = useState()
  const [user_preferences, set_preferences] = useState()
  const gender_options = [
    { key: 'male' },
    { key: 'female' },
    { key: 'prefer not to say' }]

  const options = [{ name: 'Option_1', id: 1 }, { name: 'Option_2', id: 2 }]
  const selectedValue = []
  function onSelect() {

  }
  function onRemove() {

  }

  function logout() {
    Auth.signOut()
  }

  function setbirthday(date) {
    const myMomentObject = moment(date, 'DD-MM-YYYY').toDate()
    set_birthday(myMomentObject)//{new Date(user_birthday)} 
  }

  //userId 
  useEffect(() => {
    if (props.userId !== user_id) {
      setUserId(props.userId);
    }
  }, [props.userId]);


  useEffect(() => {
    refresh()
  }, [user_id])

  const handelQchange = (event)=>{
    console.log("hi")//event.target.value)

  }

  function refresh() {
    if (user_id > 0) {
      var data = JSON.stringify({ "user_id": user_id });
      let config = user_config(data)
      axios(config)
        .then(function (response) {
          setwaiting(false)
          setUser(response.data)
          set_name(response.data.user_name)
          set_surname(response.data.user_username)
          set_username(response.data.user_username)
          set_email(response.data.user_email)
          setbirthday(response.data.user_birthday)
          set_gender(response.data.user_gender)
          set_phone(response.data.user_phone)
          set_preferences(response.data.user_preferences)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }


  function submit() {
    console.log("im here 1")
    setwaiting(true)
    if (user_id > 0) {
      var data = JSON.stringify({
        "user_id": user_id,
        "user_name": user_name,
        "user_surname": user_surname,
        "user_username": user_username,
        "user_email": user_email,
        "user_birthday": user_birthday,
        "user_gender": user_gender,
        "user_phone": user_phone,
        "user_preferences": user_preferences
      });
      console.log("im here 2")
      let config = update_user(data)
      axios(config)
        .then(function (response) {
          setwaiting(false)
          console.log(response)
          // show alert
        })
        .catch(function (error) {
          console.log(error);
        });
    }


  }


  return (
      <div className="welcomeNote settingtable">
        <table>
          <thead>
            <tr>
              <td>
                Personal Details
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Name: </p>
              </td>
              <td>
                <input className="settingcell settingcellformat" onChange={(e) => set_name(e.target.value)} value={user_name} />
              </td>
            </tr>

            <tr>
              <td>
                <p>Surname: </p>
              </td>
              <td>
                <input className="settingcell settingcellformat" onChange={(e) => set_surname(e.target.value)} value={user_surname} />
              </td>
            </tr>

            <tr>
              <td>
                <p>Username: </p>
              </td>
              <td>
                <input className="settingcell settingcellformat" onChange={(e) => set_username(e.target.value)} value={user_username} />
              </td>
            </tr>

            <tr>
              <td>
                <p>Email: </p>
              </td>
              <td>
                <input className="settingcell settingcellformat" onChange={(e) => set_email(e.target.value)} value={user_email} />
              </td>
            </tr>


            <tr>
              <td>
                <p>Birthday: </p>
              </td>
              <td>
                <DatePicker
                  className="settingcell settingcellformat"
                  format='dd-MM-yyyy'
                  selected={user_birthday}
                  onChange={(date) => set_birthday(date)} />
              </td>
            </tr>


            <tr>
              <td>
                <p>Gender: </p>
              </td>
              <td>
                <Multiselect
                  className="settingcell"
                  options={gender_options}
                  placeholder="UNKNOWN"
                  singleSelect
                  displayValue="key"
                />
              </td>
            </tr>


            <tr>
              <td>
                <p>Phone: </p>
              </td>
              <td>
                <PhoneInput
                  className="settingcell settingphone"
                  placeholder="Enter phone number"
                  value={user_phone}
                  inputClass={{
                    backgroundColor: "red"
                  }}
                  onChange={set_phone} />
              </td>
            </tr>

            <tr style={{
              justifyContent: 'center',

            }}>
              <td>
              </td>


              <td >
                <button style={{ marginLeft: '0px' }} onClick={submit} className="settingbuttons">Submit</button>
                <button style={{ marginLeft: '60px' }} onClick={refresh} className="settingbuttons">Refresh</button>
              </td>
            </tr>
            <tr style={{
              justifyContent: 'center',

            }}>
                            <td>
              </td>
              <td>
              <button className="settingbuttons"  onClick={logout}>Sign Out</button>
              </td>

            </tr>     
          </tbody>
          
        </table>

       
      </div>
      
  )
}

/*


      <div>
        <table>
          <tr>
            <td style={{textAlign:"left"}}>
              {questionnaire.questions.map((item) =>
                <>
                  <p>{item.question}</p>
                  {
                    item.answers.map((anw) =>
                            <CheckBoxSet onChange={handelQchange} 
                                radioitems={[anw]} /> )
                  }
                </>
              )}
            </td>

          </tr>
        </table>
      </div>


*/