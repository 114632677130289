import { get_user_tags, animated_background, user_aval_interv } from '../components/configs';
import IntervCard from '../components/IntervCard';
import CollapsBar from '../components/CollapsBar';
import { useState, useEffect } from 'react';
import { MDBRow } from 'mdb-react-ui-kit';
import '../styles/interv.css'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Visibility } from '@mui/icons-material';

export default function AvailableIntervs(props) {


  // store the startTime prop in local state
  const [user_id, setUserId] = useState(props.userId)
  const [interv, setInterv] = useState([])
  const [waiting, setwaiting] = useState(true)
  const [userTags, setUserTags] = useState([])
  const navigate = useNavigate();
  const [ interVisibility, setInterVisibility] = useState(true);

  useEffect(() => {
    setUserId(props.userId);
    refresh(null, null, null);
    console.log(interVisibility)
  }, [props.userId]);

  function apply_filter(data, category, provider, tags) {
    let filtered_data = data
    if (category !== null & category !== 'Category') filtered_data = filtered_data.filter((interv) => interv.interv_category.includes(category))
    if (provider !== null & provider !== "Providers") filtered_data = filtered_data.filter((interv) => interv.interv_company.includes(provider))
    if (tags !== null & tags !== 'Tags') filtered_data = filtered_data.filter((interv) => {
      let temptags = []
      if (interv.tags.includes(",")) {
        temptags = interv.tags.split(',')
      }
      else {
        temptags.push(interv.tags)
      }

      //console.log(temptags)
      const found = temptags.some(r => tags.includes(r))
      return found
    })
    
    return filtered_data
  }

  function refresh(category, provider, tags) {

    setUserId(props.userId);
    var data = JSON.stringify({ "user_id": props.userId.toString() });
    let config2 = get_user_tags(data)
    let config = user_aval_interv(data)
    let temp_tags = []
    axios(config2)
      .then(response2 => {
        if (response2.data.user_preferences?.includes(","))
          temp_tags = response2.data.user_preferences.split(',')
        else
          temp_tags = temp_tags.concat(response2.data.user_preferences)
        setUserTags(temp_tags)
      })
      .then(response2 => {
        axios(config)
          .then(response => {
            let a =[]
            if(temp_tags.length>0)
              setInterVisibility(false)
            else
              setInterVisibility(true)
            
            setwaiting(false)
            setInterv(apply_filter(response.data, category, provider, temp_tags))
          })
       //   .then(response2 =>{
        //    if (interv.length > 0) {
         //     setInterVisibility(true)}
        //    else {
         //     setInterVisibility(false)
          //    }
         // })
          .catch(function (error) {
            console.log(error);
          })

      })
      .then(response2=>{console.log(interVisibility, "3")})

  }


  return (
    <>
      {waiting &&
          <img className="wait" src={animated_background} />}
      {!waiting &&
        <>
        <table>
          <tbody className='cardstable'>
            <tr>
              <td>
                {/*Temporalily disable the filter function*/}
                {/* <CollapsBar disabled={false} key='availableinterv' parentRefresh={refresh} /> */}
                
                {interVisibility &&
                <h4 style={{  marginTop: '10px', marginLeft: '20px' }} > We need to know your preferences before we can recommend interventions. Please complete your <a href='/portal/settings'>profile settings</a>.</h4>}
                
                <MDBRow className='row-cols-1 row-cols-md-3 g-4'>
                  {
                    interv.map(item =>
                      <IntervCard
                        key={item['interv_id']}
                        imageurl={item['interv_logo']}
                        intitle={item['interv_name']}
                        descr={item['interv_desc']}
                        subs='Subscribe'
                        userid={props.userId}
                        inervid={item['interv_id']}
                      />
                    )
                  }
                </MDBRow>
              </td>
            </tr>
          </tbody>
        </table>
        </>
      }
    </>
  )
}
