import logo from '../images/logo.gif'
import leaves from '../images/leaves.png'
import logos from '../images/logos.png'
import '../styles/welcome.css'

import {
    Link
} from "react-router-dom";

export default function Welcome() {
    return (
        <div>
            <div className='welcome-page'>
                <img className='logo' src={leaves} />
                <div className='items-box'>
                    <h3 className='welcome-items'>Welcome to</h3>
                    <img src={logo} width="250" />
                    <br /><br />
                    <Link to={'/portal/home'}>
                        <button className='buttons'>Get Started</button>
                    </Link>
                </div>
            </div>
            <div >
                <img className='logos' src={logos}  />
            </div>
        </div>
    )
}
