import '../styles/salvio.css'


export default function ContactUs() {

    function submitbtn(){
        return
    }

	return (
        <div className='contactus'>

            <form className='contactform' id="contact-form" method="post" >
            <p className='contactformlabels'>Name:</p>
            <input type="text" id="name-input" placeholder="Enter name here…" className="form-control"/><br/>
            <p className='contactformlabels'>Phone:</p>
            <input type="phone"  id="phone-input" placeholder="Enter phone number" className="form-control"/><br/>
            <p className='contactformlabels'>Email:</p>
            <input type="email"  id="email-input" placeholder="Enter email here…" className="form-control"/><br/>
            <p className='contactformlabels'>How can we help you?</p>
            <textarea id="description-input" rows="8" placeholder="Enter your message…" className="form-control"></textarea><br/>
            <button type="button" onClick={submitbtn} className="settingbuttons" >Submit</button>
        </form>
        </div>
	)
}