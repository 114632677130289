import axios from "axios";
import { animated_background,user_config, user_interv, all_intervs } from '../components/configs';
import { useState, useEffect } from 'react';
import '../styles/interv.css'
import { MDBRow } from 'mdb-react-ui-kit';
import IntervCard from '../components/IntervCard';
import CollapsBar from '../components/CollapsBar';
import { Auth } from 'aws-amplify';
export default function IntervMarket(props) {

  // store the startTime prop in local state
  const [user_id, setUserId] = useState(localStorage.getItem('userId'))
  const [interv, setInterv] = useState([])
  const [waiting, setwaiting] = useState(true)
  const [regInterv, setRegInterv] = useState([])

  useEffect(() => {
    refresh(null, null, null)
  }, [])

  function refresh(category, provider, week) {
    let config = all_intervs()
    var data = JSON.stringify({ "user_id": user_id });
    //console.log(data)
    let config2 = user_interv(data)
    axios(config2)
    .then(response2 => {
      let temp = []
      response2.data.forEach((element) => temp.push(element.interv_name));      
      setRegInterv(temp)
    })
    .then(response3 =>{
      axios(config)
      .then(response => {
        setwaiting(false)
        setInterv(apply_filter(response.data, category, provider, week))
      })
      .catch(error => {
        console.log(error);
      });
    })
    .catch(function (error) {
      console.log(error);
    })

  }

  function apply_filter(data, category, provider, tags) {
    let filtered_data = data
    if (category !== null & category !== 'Category') filtered_data = filtered_data.filter((interv) => interv.interv_category.includes(category))
    if (provider !== null & provider !== "Providers") filtered_data = filtered_data.filter((interv) => interv.interv_company.includes(provider))
    if (tags !== null & tags !== 'Tags') filtered_data = filtered_data.filter((interv) => {
      let temptags = interv.tags.split(',')
      const found = temptags.some(r => tags.includes(r))
      return found
    })
    return filtered_data
  }


  return (
    <>
      {waiting &&
        <img className="wait" src={animated_background} />}
      {!waiting &&
        <table>
          <tbody className='cardstable'>
            <tr>
              <td>
                {/*Temporalily disable the filter function*/}
                {/* <CollapsBar disabled={false} key='intervmarket' parentRefresh={refresh} /> */}
                <MDBRow className='row-cols-1 row-cols-md-3 g-4'>
                  {
                    interv.map(item => {
                      if (regInterv.includes(item.interv_name))
                        return <IntervCard
                          key={item['interv_id']}
                          imageurl={item['interv_logo']}
                          intitle={item['interv_name']}
                          descr={item['interv_desc']}
                          subs='Unsubscribe'
                          userid={user_id}
                          inervid={item['interv_id']} />
                      else 
                        return <IntervCard
                          key={item['interv_id']}
                          imageurl={item['interv_logo']}
                          intitle={item['interv_name']}
                          descr={item['interv_desc']}
                          subs='Subscribe'
                          userid={user_id}
                          inervid={item['interv_id']} />
                    })
                  }
                </MDBRow>
              </td>
            </tr>
          </tbody>
        </table>
      }
    </>
  )
}