import axios from "axios";
import { useState, useEffect } from "react"
import qdata from '../Data/questions.json'
import RadioButtonSet from '../components/RadioButtonSet'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { update_tags } from "../components/configs";
import {useNavigate} from "react-router-dom";
import AvailableIntervs from "./AvailableIntervs";

export default function ProfileSetting(props) {
    const [q1, setQ1] = useState("")
    const [q1i, setQ1i] = useState([])
    const [q2, setQ2] = useState("")
    const [q3, setQ3] = useState("")
    const [q3m,setq3m]= useState("") //memory for q3
    const [q3vis, setQ3vis] = useState("hidden")
    const navigate = useNavigate();

    function handleQ1change(event) {
        
        const temptags = qdata['questions'][0]['tags']
        let arr = q1i
        if (arr.includes(temptags[event])){
            console.log("have it")
            arr = arr.filter(function(item) {
                return item !== temptags[event]
            })}
        else
            arr.push(temptags[event])     
        setQ1i(arr)

        //Q3 change of visibility
        if (arr.includes("exercise")){
            setQ3vis("visible")
            setQ3(q3m)    
        }
        else{
            setQ3vis("hidden")
            setQ3("")
       }
    }

    function handelQ2Achange(event) {
        let res = event.target.value
        let ind = qdata['questions'][1]['answers'].indexOf(res)
        setQ2(qdata['questions'][1]['tags'][ind])
        console.log(qdata['questions'][1]['tags'][ind])
    }

    function handelQ3change(index, event) {
        setQ3(event.target.value)
        setq3m(event.target.value)
    }

    function handleSubmit(event) {
        
        let tags =[]
        
        //Q1
        tags = tags.concat(q1i)

        //Q2
        tags = tags.concat(q2)

        //Q3
        //find the q3 answered index
        let ind = qdata['questions'][2]['answers'].indexOf(q3);       
        tags.push(qdata['questions'][2]['tags'][ind])

        var filtered = tags.filter(function (el) { return el != ''; });
        
        var data = JSON.stringify({
            "user_id": props.userId,
            "user_preferences": filtered.toString()
        });

        let config = update_tags(data)
        axios(config)
            .then(function (response) {
            console.log(response.data)
            if (window.confirm('Thanks for sharing! This helps us understand how Salvio can help you. We`ve matched your preferences against our programs. Please check ok to view personalised recommendations and choose your preferred options or cancel to stay on this page. If your preferences change in future, just update your profile on the current page at any time and we`ll update your recommendations.'))
            {
              
              navigate('/portal/avinterv');
            };
            })
            .catch(function (error) {
            console.log(error.data);
            });
        
          
    }

    return (
        <div className='welcomeNote'>
            <br />
            <p className='about-text'>These questions will help
                Salvio understand what you want and identify health programs
                that fit your preferences. You can repeat the questions
                again in future if your preferences change.</p><br />
            {
                qdata.questions.map((item, index) => {
                    if (item.Q == 1)
                        return <div style={{ textAlign: "left" }}>
                        <br />
                        <p key={item.Q}>{item.question}</p>
                        {
                            item.answers.map((answers, indexh) => {
                                return (<FormControlLabel
                                    label={answers}
                                    control={<Checkbox onChange={handleQ1change.bind(this, indexh)} />}
                                />)
                            })
                        }
                        <br /><br />
                        
                    </div>

                    if (item.Q == 2)
                        return <div style={{ textAlign: "left" }}>
                        <p key={item.Q}>{item.question}</p>
                        <RadioButtonSet
                            value={q2}
                            change={handelQ2Achange.bind(this)}
                            key={"RB" + index}
                            disabled={item.disabled}
                            radioitems={item.answers} />
                        <br />

                    </div>

                    if (item.Q == 3)
                        return <div style={{ visibility:q3vis, textAlign: "left" }}>
                            <br />
                            <p key={item.Q}>{item.question}</p>
                            <RadioButtonSet
                                checked={q3vis}
                                change={handelQ3change.bind(this, index)}
                                key={"RB" + index}
                                radioitems={item.answers} />
                            <br />
                        </div>
                }
                )
            }
            <br /><br />
            <p>Before you navigate away from this page, kindly wait for a pop-up window to confirm your selection.</p>
            <br />
            <button 
                className='signout'
                style={{position : 'relative'}}
                onClick={handleSubmit}>submit</button>
        </div >
    )
}
