export const animated_background = 'https://media.giphy.com/media/WjASUiW14fjhDWq3lT/giphy.gif'

export function user_config(data) {

  return {
    method: 'post',
    url: 'https://nthqnwz6g9.execute-api.ap-southeast-2.amazonaws.com/dev/user',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

}

export function reg_user_config(data) {

  return {
    method: 'post',
    url: 'https://nthqnwz6g9.execute-api.ap-southeast-2.amazonaws.com/dev/reguser',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

}



export function user_interv(data) {

  return {
    method: 'post',
    url: 'https://nthqnwz6g9.execute-api.ap-southeast-2.amazonaws.com/dev/regitervs',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

}

export function user_aval_interv(data) {

  return {
    method: 'post',
    url: 'https://nthqnwz6g9.execute-api.ap-southeast-2.amazonaws.com/dev/avinterventions',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };
}


export function all_intervs() {

  return {
    method: 'get',
    url: 'https://nthqnwz6g9.execute-api.ap-southeast-2.amazonaws.com/dev/interventions',
    headers: {}
  };
}



export function history(data) {

  return {
    method: 'post',
    url: 'https://nthqnwz6g9.execute-api.ap-southeast-2.amazonaws.com/dev/history',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };
}

export function subscribe(data) {

  return {
    method: 'post',
    url: 'https://nthqnwz6g9.execute-api.ap-southeast-2.amazonaws.com/dev/subscinterv',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };
}


export function unsubscribe(data) {

  return {
    method: 'post',
    url: 'https://nthqnwz6g9.execute-api.ap-southeast-2.amazonaws.com/dev/unsubscinterv',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };
}


export function get_categories() {

  return {
    method: 'get',
    url: 'https://nthqnwz6g9.execute-api.ap-southeast-2.amazonaws.com/dev/categories',
    headers: {}
  };
}

export function update_user(data) {
  return {
    method: 'post',
    url: 'https://nthqnwz6g9.execute-api.ap-southeast-2.amazonaws.com/dev/userupdate',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };
}

export function update_tags(data) {
  return {
    method: 'post',
    maxBodyLength: Infinity,
      url: 'https://nthqnwz6g9.execute-api.ap-southeast-2.amazonaws.com/dev/updatetags',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
  };
}

export function get_user_tags(data) {
  return {
    method: 'post',
      url: 'https://nthqnwz6g9.execute-api.ap-southeast-2.amazonaws.com/dev/usertags',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
  };
}
