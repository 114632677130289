import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useState } from 'react';

export default function RadioButtonSet(props) {
    
    const [value, setValue] = useState( props.value);
    
    const handleQchange = (event) => {
        setValue(event.target.value)
        props.change(event)
    }

    return (
        <FormControl >
        <FormLabel id="demo-row-radio-buttons-group-label">{props.title}</FormLabel>
        <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            key={value}
            onChange={handleQchange}
        > {
            props.radioitems.map((item,index) =>
            <>
              <FormControlLabel 
                value={item}
                key={index}
                control={
                    <Radio
                        value={props.checked == true ? false : item}
                        key={"a"+item+index} 
                        disabled={props.disabled}/>}
                label={item} />
                <br/>
            </>
            )
          }
        </RadioGroup>
    </FormControl>
    )
}
