import './App.css';
import './styles/welcome.css'

import { Authenticator } from '@aws-amplify/ui-react';
import {
  Route,
  Routes
} from "react-router-dom";
import './styles/pages.css'
import Welcome from './pages/Welcome';
import Salvio from './pages/Salvio'
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);
Auth.configure(awsconfig);
const formFields = {
  signUp: {
    phone_number: {
      dialCode: '+61'
    },
  },
}

export default function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/" element={<Welcome />} />
        <Route path="/portal/*" element={
          <Authenticator className='authenticator' formFields={formFields}> { <Salvio />}  </Authenticator>} />
      </Routes> 
    </div>
)
}
