import '../styles/pages.css';
import '../styles/home.css'
import '../styles/salvio.css'
import chev from '../images/chev.png'
import members from '../images/ipanmembers.jpeg'
import emailogo from '../images/emailogo.png'
import stf1 from '../images/staff_1.jpg'
import stf2 from '../images/staff_2.jpg'
import stf3 from '../images/staff_3.jpg'
import stf4 from '../images/staff_4.jpg'
import stf5 from '../images/staff_5.jpg'
import axios from "axios";
import { useState ,useEffect} from 'react';
import { get_user_tags } from '../components/configs';

export default function Main(props) {
  const [user_id] = useState(localStorage.getItem('userId'))
  const [tags, setTags] = useState([])
  let user_name = props._user.name

  if (typeof user_name === '') {
    const r3 = user_name.toUpperCase();
  }

  function getUserTags(){
    var data = JSON.stringify({ "user_id": user_id.toString() });
    let config2 = get_user_tags(data)
    let temp_tags = []
    axios(config2)
      .then(response2 => {
        if (response2.data.user_preferences.includes(","))
          temp_tags = response2.data.user_preferences.split(',')
        else
          temp_tags = temp_tags.concat(response2.data.user_preferences)
          setTags(temp_tags);
        console.log(temp_tags,'tags');
     
      })
  }
  useEffect(() => {
    if(user_id){
      getUserTags()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='welcomeNote'>
      <div className="gap" />
      <div className="gap" />

      <h2 className='titles_mob'> WELCOME TO SALVIO {user_name} </h2>
      <div className="gap" />

      <h2 className='about-titles titles_mob'> About Salvio</h2>
      <p className='about-text-body titles_mob'>Salvio is a web-based digital platform and hosts different lifestyle behaviour change programs. Each intervention targets different aspects of lifestyle change: diet, exercise, medication adherence amongst others. Salvio will help guide you to choose the one that best suits your needs.</p>
      <h3><a href={tags?.length > 0 ? '/portal/avinterv' : '/portal/settings'}>Let's Get Started!</a></h3>
      <br /><br /><br />
      <img style={{ width: '40vh', height: '5%' }} src={chev} />
      <br />

      <h2 className='titles_mob' style={{ textAlign: "center" }} > About Us </h2>
      <br />
      <h4 style={{ marginLeft: '10px', textAlign: 'left' }}> Our Mission </h4>
      <p className='about-text mainpage_table'>To support you in making healthy lifestyle changes to prevent future cardiovascular events. </p>

      <h4 style={{ marginLeft: '10px', textAlign: 'left' }}> By what means? </h4>
      <p className='about-text mainpage_table'>Digital interventions have the potential to support people with heart disease in making changes to their lifestyles. We have developed many digital programs to help support lifestyle changes, and our Salvio platform brings them together in one location. </p>

      <h4 style={{ marginLeft: '10px', textAlign: 'left' }}> Your choice </h4>
      <p className='about-text mainpage_table'>You will be asked to complete a very brief questionnaire to determine the best intervention for you. You will be part of the intervention for 12 weeks. Via Salvio, you will have different options to choose from to support you to be active, eat healthy etc. Get started today!</p>

      <h4 style={{ marginLeft: '10px', textAlign: 'left' }}> Meet the Team </h4>

      <table>
        <tr>
          <td>
            <ul>
              <li className='team_members_style'>Ralph Maddison - Principal Investigator</li>
              <li className='team_members_style'>Yuxin Zhang - Digital Health Research Fellow</li>
              <li className='team_members_style'>Jonathan Rawstorn - Digital Health Research Fellow</li>

            </ul>
          </td>
          <td>
            <ul>
              <li className='team_members_style'>Jenna McVicar - Health Researcher</li>
              <li className='team_members_style'>Mark Horrigan - Principal Investigator</li>
              <li className='team_members_style'>Louise Brown - Cardiology Researcher</li>
            </ul>
          </td>
        </tr>
      </table>

      <br /><br /><br />
      <img style={{ width: '40vh', height: '5%' }} src={chev} />
      <br />
      <h2> Contact Us </h2>

      <table>
        <tbody>
          <tr>
            <td >
              <p className='about-text mainpage_table'>If you require any further information or have any questions regarding Salvio, please feel free to contact the research team:</p>
            </td>
            <td className='mainpage_table_thirty'>
            </td>
          </tr>
        </tbody>
      </table>


      <table style={{ width: '100vh' }}>
        <tr>
          <td style={{ width:'40vh', textAlign: 'right'}}>
            <img style={{ width: '3vh', height: '3%' }} src={emailogo} />
          </td>
          <td style={{ textAlign: 'left' }}>
            <p style={{marginTop: '15px'}}>Email : <a href='salvio.admin@deakin.edu.au'>salvio.admin@deakin.edu.au</a></p>
          </td>
        </tr>
      </table>

      <br /><br />
    </div>
  )
}
