import '../styles/interv.css'
import { useState, useEffect } from 'react';
import {animated_background, user_interv } from '../components/configs';
import axios from "axios";
import IntervCard from '../components/IntervCard';
import { MDBRow } from 'mdb-react-ui-kit';
import CollapsBar from '../components/CollapsBar';

export default function RegisteredInterv(props) {

  // store the startTime prop in local state
  const [user_id, setUserId] = useState(localStorage.getItem('userId'))
  const [interv, setInterv] = useState([])
  const [waiting, setwaiting]= useState(true)
  //userId 
  useEffect(() => {
    if (props.userId !== user_id) {
      setUserId(props.userId);
    }
  }, [props.userId]);

  useEffect(() => {
    refresh(null, null, null)
  }, [user_id])

  function refresh(category, provider, week) {
    if (user_id > 0) {
      var data = JSON.stringify({ "user_id": user_id });
      let config = user_interv(data)
      axios(config)
        .then(function (response) {
          setwaiting(false)
          setInterv(apply_filter(response.data,category, provider, week ))

        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  
  function apply_filter(data, category, provider, week){
    let filtered_data = data
    if (category !==  null & category!=='Category') filtered_data = filtered_data.filter( (interv) => interv.interv_category.includes(category))
    if (provider !==  null & provider!=="Providers") filtered_data = filtered_data.filter( (interv) => interv.interv_company.includes(provider))
    if (week !== null & week!=='Weeks') filtered_data = filtered_data.filter( (interv) => interv.interv_dur_week=week)
    return filtered_data
  }

  return (
    <>
      {waiting &&
        <img className="wait" src={animated_background} />}
      {!waiting &&
        <table>
          <tbody className='cardstable'>
            <tr>
              <td>
                {/*Temporalily disable the filter function*/}
              {/* <CollapsBar disabled={false} key='registinterv' parentRefresh={refresh}/> */}
                <MDBRow className='row-cols-1 row-cols-md-3 g-4'>
                  {
                    interv.map(item =>
                      <IntervCard
                        key={item['interv_id']}
                        imageurl={item['interv_logo']}
                        intitle={item['interv_name']}
                        descr={item['interv_desc']}
                        subs='Unsubscribe'
                        userid={props.userId}
                        inervid={item['interv_id']}
                      />
                    )
                  }
                </MDBRow>
              </td>
            </tr>
          </tbody>
        </table>
      }
    </>
  )
}
