import { useEffect, useState } from "react";
import { animated_background, history } from '../components/configs';
import axios from "axios";
import {
  CircularProgressbar,
  buildStyles
} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import '../styles/probar.css'


export default function Progress(props) {
  // store the startTime prop in local state
  const [user_id, setUserId] = useState()
  const [data, setData] = useState([]);
  const [waiting, setwaiting] = useState(true)
  const [charts, setCharts] = useState([])
  const [loaded, setload] = useState()
  const [chartsLoad, setChartLoad] = useState(false)

  function sortdata(data) {
    var ascending = data.sort((a, b) => Number(a.sms_id) - Number(b.sms_id));
    return ascending
  }
/*
  function process_time(time) {
    if (time != null) {
      let dot_index = time.indexOf('.');
      var subStr = time.substr(1, dot_index - 1);
      subStr = subStr.replace("T", " / ")
      return subStr
    }
    else
      return null
  }
*/
  async function drawCircleChart(sorted_data) {

    let data = await (sorted_data)

    let grouped_result = data,
      result = grouped_result.reduce(function (r, a) {
        r[a.intervention_id] = r[a.intervention_id] || [];
        r[a.intervention_id].push(a);
        return r;
      }, Object.create(null));

    let interventions = []
    for (let interv in result) {
      interventions.push(interv)
    }

    let temp_charts = []
    // for each group of sms 
    for (let interv in interventions) {

      let int_name = data.filter(function (item) { return item.intervention_id == interventions[interv]; })[0]['interv_name']
      // Group sms
      // count the total
      let count_sms = data.filter(function (item) { return item.intervention_id == interventions[interv]; }).length

      // for each group of sms count items with sent date and percentile
      let sent_sms_count = data.filter(function (item) { return item.intervention_id == interventions[interv] && item.time != null; }).length
      let sent_sms_perc = (sent_sms_count / count_sms) * 100

      // for each group of sms count items with no sent date and percentile
      let not_sent_count = data.filter(function (item) { return item.intervention_id == interventions[interv] && item.time == null; }).length
      let not_sent_perc = (not_sent_count / count_sms) * 100

      

      temp_charts.push({
        "sent": sent_sms_perc.toFixed(2),
        "not-sent": not_sent_perc.toFixed(2),
        "interv": int_name
      })
    }
    console.log(interventions.indexOf(interventions[interventions.length -1]))
    setCharts(temp_charts)
    setChartLoad(true)
  }

  useEffect(() => {

    if (props.userId !== user_id) {
      setUserId(props.userId);
    }

    if (props.userId != 0) {
      setChartLoad(false)
      var conf_data = JSON.stringify({ "user_id": props.userId });
      let config = history(conf_data);
      let sorted_data = []
      axios(config)
        .then(response => {
          sorted_data = sortdata(response.data)
        })
        .then(response => {
          setData(sorted_data)
        })
        .then(response => {
          drawCircleChart(sorted_data)
        })
        .then(response => {
          setwaiting(false)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    else {
      console.log("no user")
    }

  }, [props.userId])

  const percentage = 16;
  return (
    <>
      {waiting &&
        <img className="wait" src={animated_background} />}

      {chartsLoad &&
        charts.map(item =>
          <div>
            <h2 style={{height:'2vh', marginLeft:'10vh'}}>{item['interv']}</h2>
            <br />
            <div className="chartStyle">
              <CircularProgressbar
                styles={buildStyles({
                  pathColor: "#039cb7",
                  trailColor: "#8dc6d2"
                })}
                className="CircularProgressbar-background"
                value={item['sent']}
                text={`${item['sent']}%`} />
            </div>
          </div>
        )
      }
    </>
  )
}