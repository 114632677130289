import {
    MDBCard,
    MDBCardImage,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBRow,
    MDBCol
} from 'mdb-react-ui-kit';
import { Alert, Button } from 'react-bootstrap';
import React from 'react';
import "../styles/interv.css"
import { useState, useEffect } from 'react';
import axios from "axios";
import { animated_background, subscribe, unsubscribe } from '../components/configs';

export default function IntervCard(props, { substat }) {
    const [show, setShow] = useState(true)
    const [waiting, setwaiting] = useState(false)
    const [title, setTitle] = useState()
    const [subcolor, setSubColor] = useState("sub_button")
    const [btndisable, setBtnDisabled] = useState(false)
    const [alertsuccess, Setalertsuccess] = useState(false)
    const [alertunsubs, Setalertunsubs] = useState(false)
    const [waitingincard, setwaitingincard] = useState(false)
    
    useEffect(() => {
        setTitle(props.subs)      
        if (title == "Unsubscribed")
            setSubColor("sub_button_unsubscribed")
        
        // setting exception for the welcome intervention    
        if (props.inervid == 70000){
            setTitle("Unsubscribable")
            setBtnDisabled(true)
            setSubColor("sub_button_disabled")}
    }, [])

    function setbtn() {
        setShow(show => !show);
        setwaitingincard(true)
        var data = JSON.stringify({
            "user_id": props.userid,
            "interv_id": props.inervid
        });

        if (props.subs == "Subscribe") {

            let config = subscribe(data)
            // Subscribe
            axios(config)
                .then(function (response) {
                    if (response.data = "Already Registered!")
                        console.log("Already Registered!")
                    setTitle("Subscribed")
                    setSubColor("sub_button_subscribed")
                    setwaitingincard(false)
                    Setalertsuccess(true)
                })
                .catch(function (error) {
                    console.log(error);
                    setwaitingincard(false)
                });
        }
        else {
            let config = unsubscribe(data)
            // Unsubscribe
            axios(config)
                .then(function (response) {
                    if (response.data = "error_107")
                        console.log("Already Unsubscribed!")

                    setTitle("Unsubscribed")
                    setSubColor("sub_button_unsubscribed")
                    setwaitingincard(false)
                    Setalertunsubs(true)
                })
                .catch(function (error) {
                    console.log(error);
                    setwaitingincard(false)
                });
        }
    }

    return (
        <>
            {waiting &&
                <img className="wait" src={animated_background} />}
            <MDBCol>
                <MDBCard className='h-100'>
                    <MDBCardImage
                        className='cardimage'
                        src={props.imageurl}
                        alt='...'
                        position='top'
                    />
                    {alertsuccess &&
                          <Alert variant="success" style={{ width: "100%" }}>
                                <Alert.Heading>
                                    Subscription successful
                                </Alert.Heading>
                            </Alert>}

                    {alertunsubs &&
                          <Alert variant="danger" style={{ width: "100%" }}>
                          <Alert.Heading>
                                Subscription cancelled
                          </Alert.Heading>
                        </Alert>}
                    <MDBCardBody>
                        <MDBCardTitle className='cardtitle'>{props.intitle}</MDBCardTitle>
                        {!waitingincard && <MDBCardText className='description'>{props.descr}</MDBCardText>}
                    </MDBCardBody>

                    {waitingincard &&
                        <img className="wait-card" src={animated_background} />}


                    <Button disabled={btndisable} className={subcolor} onClick={setbtn}>{title}</Button>
                </MDBCard>
            </MDBCol>
        </>
    )
}