import { useLocation, useNavigate, Route, Routes , Link} from "react-router-dom";
import { user_config, subscribe, reg_user_config } from '../components/configs';
import { withAuthenticator } from '@aws-amplify/ui-react-v1';
import { Nav, Navbar } from "react-bootstrap";
import { Amplify, Auth } from 'aws-amplify';
import { MDBRow } from "mdb-react-ui-kit";
import React, { useEffect } from 'react';
import { useState } from 'react';
import awsconfig from '../aws-exports';
import RegisteredIntervs from './RegisteredIntervs';
import AvailableIntervs from './AvailableIntervs';
import "bootstrap/dist/css/bootstrap.min.css";
import ProfileSetting from "./ProfileSetting";
import IntervMarket from "./IntervMarket";
import logo from '../images/logo.gif';
import Main from './main';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import Settings from './Settings';
import Progress from './Progress';
import '../styles/salvio.css';
import '../styles/login-styles.css';
import '../styles/pages.css';
import axios from "axios";


Amplify.configure(awsconfig);
Auth.configure(awsconfig);

/*Auth User details:
birthdate, email, email_verified,
family_name, name, phone_number, phone_number_verified, sub */

// Checks user registration status
function register_user(user) {
    var data = JSON.stringify({
        "user_name": user.name,
        "user_surname": user.family_name,
        "user_username": user.email,
        "user_email": user.email,
        "user_birthday": user.birthdate,
        "user_gender": "",
        "user_phone": user.phone_number
    });
    
    axios(reg_user_config(data))
        .then(function (response) {
            if (response.data['status'] = "Already Registered!")
                console.log(response)
            else
                console.log("not registered user")
                // means user was not registered before
                // sign for welcome intervention
                var data_2 = JSON.stringify({
                    "user_id": (response.data['id']).toString() ,
                    "interv_id": "70000"
                });
                let config_2 = subscribe(data_2)
                axios(config_2)
                    .then(function (response) {
                        console.log("registered for welcome")
                        window.location.reload(false);
                        // uncomment to reload from server
                        //window.location.reload(true);
                        const navigate = useNavigate();
                        console.log(response)
                        navigate('/portal/settings');
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
        })
        .catch(function (error) {
            console.log(error);
        });
}


function Salvio() {
    const [user_id, set_user_id] = useState(0);
    const [userauth, set_user_auth] = useState(0);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const location = useLocation();
    const navigate = useNavigate();
    const today = new Date();
    const [pageid, setpageID] = useState();
    const handleActive = (pageid) => {
        setpageID(pageid);
    };

    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return innerWidth;
      }

    useEffect(() => {
        console.log(location.pathname); 
        }, [location]);

    // get user details from auth
    useEffect(() => {
        set_user_auth(Auth.Credentials.Auth.user.attributes)
        const user_email = Auth.Credentials.Auth.user.attributes.email;
        var data = JSON.stringify({ "user_email": user_email });
        axios(user_config(data))
            .then(function (response) {
                if (!response.data ) {
                    console.log("Not Registered")
                    console.log("Registering Now .. ")
                    var resp = register_user(Auth.Credentials.Auth.user.attributes)
                    if (resp = true)
                        console.log("Registered !")
                    else
                        console.log("error in registration") //=> should automatically log out and show a message
                }
                else{

                   set_user_id(response.data['user_id']);
                   // setter
                   localStorage.setItem('userId', response.data['user_id']);

                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    
    return (
    <div className="mainpage">
        <MDBRow >
            <Navbar className="mainNavBar" collapseOnSelect expand="lg" >
                <Navbar.Brand className="brandlogo" href="/portal/home">
                    <img className="mlogo" src={logo} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />  
    
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav activeKey={location.pathname} >
                        <Nav.Link onClick={() => handleActive('home')} as={Link} to="/portal/home" style={{   fontSize: "calc(5px + 1.5vmin)",  fontFamily: "Arial, Helvetica, sans-serif",fontWeight: 400, color: pageid === 'home' ? 'rgba(3, 143, 166, 0.95)' : '' , textDecoration: pageid==='home' ? 'underline ' : ''}}>Home</Nav.Link>
                        <Nav.Link onClick={() => handleActive('reginterv')}  as={Link} to="/portal/reginterv" style={{fontSize: "calc(5px + 1.5vmin)", fontWeight: 400, color: pageid === 'reginterv' ? 'rgba(3, 143, 166, 0.95) ' : '' , textDecoration: pageid==='reginterv' ? 'underline ' : ''}}>Registered Interventions</Nav.Link>
                        <Nav.Link onClick={() => handleActive('avinterv')} as={Link} to="/portal/avinterv" style={{fontSize: "calc(5px + 1.5vmin)",fontWeight: 400, color: pageid === 'avinterv' ? 'rgba(3, 143, 166, 0.95) ' : '' , textDecoration: pageid==='avinterv' ? 'underline ' : ''}}>Recommended Interventions</Nav.Link>   
                        <Nav.Link onClick={() => handleActive('intrvmarket')} as={Link} to="/portal/intrvmarket" style={{fontSize: "calc(5px + 1.5vmin)",fontWeight: 400, color: pageid === 'intrvmarket' ? 'rgba(3, 143, 166, 0.95) ' : '' , textDecoration: pageid==='intrvmarket' ? 'underline ' : ''}}>All Interventions</Nav.Link>         
                        <Nav.Link onClick={() => handleActive('progress')} as={Link} to="/portal/progress" style={{fontSize: "calc(5px + 1.5vmin)",fontWeight: 400, color: pageid === 'progress' ? 'rgba(3, 143, 166, 0.95) ' : '' , textDecoration: pageid==='progress' ? 'underline ' : ''}}>Interventions History</Nav.Link>
                        <Nav.Link onClick={() => handleActive('settings')} as={Link} to="/portal/settings" style={{fontSize: "calc(5px + 1.5vmin)",fontWeight: 400, color: pageid === 'settings' ? 'rgba(3, 143, 166, 0.95)' : '' , textDecoration: pageid==='settings' ? 'underline ' : ''}}>Profile Settings</Nav.Link>
                        {windowSize < 1500 && 
                        <Nav.Link href="/portal/home" style={{fontWeight: 'bold'}} onClick={() => { Auth.signOut();}}>Profile</Nav.Link>}
                    </Nav>
                    
                    {windowSize > 1500 &&
                    <button
                        className='signout'
                        onClick={() => {
                            
                            navigate('/portal/profile');
                        }}
                        variant="outlined" > Profile</button>}

                </Navbar.Collapse>
            </Navbar>
        </MDBRow>
        <MDBRow className='main'>
            <main className='main'>
                <Routes>
                    <Route path="/home" element={<Main _user={userauth} />} />
                    <Route path="/contact-us" exact element={<ContactUs />} />
                    <Route path="/reginterv" exact element={<RegisteredIntervs userId={user_id} />} />
                    <Route path="/avinterv" exact element={<AvailableIntervs userId={user_id} />} />
                    <Route path="/intrvmarket" exact element={<IntervMarket userId={user_id}/>} />
                    <Route path="/progress" exact element={<Progress userId={user_id} />} />
                    <Route path="/settings" exact element={<ProfileSetting userId={user_id} />} />
                    <Route path="/profile" exact element={<Settings userId={user_id} />} />
                </Routes>
                <div className='footer-panel'>
                <a>© Copyright Deakin University - {today.getFullYear()}</a>
            </div>
            </main>
        </MDBRow>
    </div>
    )
}

export default withAuthenticator(Salvio);
//<Nav.Link onClick={() => handleActive('contact-us')} as={Link} to="/portal/contact-us" style={{fontSize: "calc(5px + 1.5vmin)",fontWeight: 400, color: pageid === 'contact-us' ? 'rgba(3, 143, 166, 0.95) ' : '' , textDecoration: pageid==='contact-us' ? 'underline ' : ''}}>Contact Us</Nav.Link>
//